import { Auth } from 'aws-amplify';
import { navigate } from 'gatsby-link';
import jwtDecode, { JwtPayload } from 'jwt-decode';

export const gatsbyUser = 'gatsbyUser-' + process.env.GATSBY_USER_POOL_WEB_CLIENT_ID;

const isBrowser = typeof window !== `undefined`;

export const setUser = (user: any, roles: string[]) => (window.localStorage[gatsbyUser] = JSON.stringify({user: user, roles: roles}));

interface CognitoJwtPayload extends JwtPayload {
    "custom:groups" : string | null | undefined;
}

const getUser = (): any => {
    if (window.localStorage[gatsbyUser]) {
        const user = JSON.parse(window.localStorage[gatsbyUser]);
        return user ? user.user : {};
    }
    return {};
};

export const getRoles = (): string[] => {
    if (isBrowser && window.localStorage[gatsbyUser]) {
        const user = JSON.parse(window.localStorage[gatsbyUser]);
        return user ? user.roles : [];
    }
    return [];
};

export const isLoggedIn = (): boolean => {
    if (!isBrowser) return false;

    const user = getUser();
    if (user) {
        return !!user.username;
    } else {
        return false;
    }
};

export const getCurrentUser = (): any => {
    return isBrowser && getUser();
};

export const logout = (): void => {
    Auth.signOut({ global: true })
        .then(() => {
            if (!isBrowser) return;
            setUser({}, []);
            navigate('/');
        })
        .catch((err: any) => console.log(err));
};

export const onAuthStateChange = (authState: string ) : Promise<any> => {
    return Auth.currentAuthenticatedUser({
        bypassCache: false,  // Optional, by default is false. If set to true, this call will send a request to Cognito to get the latest user data
    }).then(user => {
            if (authState != 'signedIn') {
                return null;
            }
            return user;
        },
    ).then(user => {
            if (user) {
                const token = user.getSignInUserSession()!.getIdToken().getJwtToken();
                const decoded = jwtDecode<CognitoJwtPayload>(token);
                let stringArray = decoded['custom:groups'];
                stringArray = stringArray ? stringArray : "";
                stringArray = stringArray.replace('[', '');
                stringArray = stringArray.replace(']', '');
                const maiaRoles: string[] = stringArray.split(', ');
                setUser(user, maiaRoles ? maiaRoles : maiaRoles);
            }
            return user;
        },
    ).catch((err) => {
        console.log(err);
        setUser(null, []);
        return null;
    });
};

export const getIdToken = async () : Promise<string> => {
  const token = await Auth.currentAuthenticatedUser({
    bypassCache: false,  // Optional, by default is false. If set to true, this call will send a request to Cognito to get the latest user data
  }).then(user => {
      if (!user) {
        return null;
      }
      return user;
    },
  ).then(user => {
    if (user) {
      return user.getSignInUserSession()!.getIdToken().getJwtToken();
    }
    return '';
  });
  return token;
};


export const isPermittedForUserRoles = (client: string, permittedRoles: string[], isPreviewOnly: boolean) : boolean => {

    const allowedRoles = permittedRoles
        .map((role) => {
            return 'maia-' + client + '-' + role;
        });

    const previewRole = 'maia-' + client + '-preview';

    // these roles are sanitized roles from AD, in case Okta is sending us roles we should not be seeing
    const userRoles = getRoles().filter((role) => role.startsWith('maia-'));

    // if the user's roles are within the allowed roles then maybe show
    // if there are no allowed roles defined then assume that everyone may view the contents
    const isMaybeAllowed = allowedRoles.length == 0 || userRoles.filter((value) => allowedRoles.includes(value)).length > 0;

    // show if the component is preview only and user has permissions, otherwise just show
    if (isMaybeAllowed && (!isPreviewOnly || userRoles.includes(previewRole))) {
        return true;
    } else {
        return false;
    }
};